import React, { useState } from "react";


import Azure from "../assets/imgs/Azure.png";
import CSharp from "../assets/imgs/csharp.png";
import css3 from "../assets/imgs/css3.png";
import Html5 from "../assets/imgs/html5.png";
import react from "../assets/imgs/React.png";
import Sql from "../assets/imgs/SQL.png";
import Typescript from "../assets/imgs/Typescript.png";
import visualstudio from "../assets/imgs/visualstudio.png";
import visualstudiocode from "../assets/imgs/visualstudiocode.png";
import webpack from "../assets/imgs/webpack.png";



import "./RandomImage.css";

const images: string[] = [Azure, CSharp, css3, Sql, Html5, react, Typescript, visualstudio, visualstudiocode, webpack];

function makeNewPosition(): number[] {
    const h: number = window.innerHeight - 50;
    const w: number = window.innerWidth - 50;

    const x: number = Math.floor(Math.random() * w);
    const y: number = Math.floor(Math.random() * h);
    const index: number = Math.floor(Math.random() * images.length);

    return [x, y, index];
}


const RandomImage: React.FC = () => {
    const [x, y, index] = makeNewPosition();
    const [position, setPosition] = useState({ x: x, y: y, index: index });
    const [animationInProgress, SetAnimationInProgress] = useState(false);
    const [startDelay, SetStartDelay] = useState(true);

    setTimeout(() => {
        SetStartDelay(false);
    }, Math.floor(Math.random() * 6000));

    const animationComplete: any = () => {
        SetAnimationInProgress(false);
        setPosition({ x: x, y: y, index: index });
    };

    return <div id={position.x + "_" + position.y + "_" + position.index} className="thedot">
        <img alt={`Illustrating field of expertise`}
            className={animationInProgress ? "animation2" : "animation"} onAnimationStart={() => SetAnimationInProgress(true)}
            onAnimationEnd={() => animationComplete()} src={images[position.index]}
            style={{
                visibility: !startDelay ? "visible" : "hidden",
                left: position.x,
                top: "-200px",
                animationDelay: Math.floor(Math.random() * 3) + "s"
            }} />
    </div>;
};

export default RandomImage;