import React, { useState, createRef } from "react";
import "./App.css";
import { Textfit } from "react-textfit";
import RandomImage from "./components/RandomImage";
import bubbles from "./assets/imgs/ico.png";
import MailTo from "react-protected-mailto";


interface ItextContainerProps {
  children: any;
  className: string;
  mode?: string;
}

const TextContainer: React.SFC<ItextContainerProps> = (props: ItextContainerProps) => {
  const [initiated, setInitiated] = useState(true);
  const ref: any = createRef();

  return <Textfit ref={ref} onAnimationEnd={() => setInitiated(false)}
    className={initiated ? "initiated" : props.className}
    mode={props.mode !== undefined ? props.mode : "single"}>{props.children}</Textfit>;
    
};

const App: React.FC = () => {

  window.addEventListener("load", () => {
    var delay: number = 0.5;
    var nodes: NodeListOf<HTMLElement> = document.querySelectorAll(".initiated");
    for (var i: number = 0; i < nodes.length; i++) {
      nodes[i].classList.add("link");
      var calc: number = (delay * i);
      nodes[i].style.animationDelay = calc + "s";
    }
  });

  return (
    <div className="App">
      <RandomImage key="1"></RandomImage>
      <RandomImage key="2"></RandomImage>
      <RandomImage></RandomImage>
      <RandomImage></RandomImage>
      <RandomImage></RandomImage>
      <div className="info-board">
        <div className="logoContainer">
          <img src={bubbles} className="bubbles" />
          <TextContainer className="link">
            Scimus AB
          </TextContainer>
        </div>
        <div className="name-title">
          <TextContainer className="link">
            Mats Jakobsson
          </TextContainer >
          <TextContainer className="link" mode="single">
            Senior Full-Stack web application developer
          </TextContainer>
        </div>
        <TextContainer className="alink">
          <MailTo email="mats.jakobsson@scimus.se"/>
        </TextContainer>
      </div>
    </div>);
};

export default App;
